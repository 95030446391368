/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';

html, body { height: 100%; }
body { margin: 0; font-family: 'Times New Roman', Times, serif !important; 
    background: url('assets/images/background.jpg') no-repeat center center fixed; /* Path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; /* Ensure it covers the entire viewport */
    margin: 0; /* Remove default margin */

  }

  @font-face {
    font-family: 'Merriweather';
    src: url('assets/fonts/Merriweather.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


 